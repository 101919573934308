import React from 'react'
import './SignupSuccess.css'

const SignupSuccess = () => {
  return (
    <div className="loginSuccessContainer">
        <div className="divContainer">
        <div className="login_1_heading">TopUpX</div>
        <div>
            <div className="div1">You have successfully created your </div>
            <div className="div1">account!</div>
        </div>
        </div>
    </div>
  )
}

export default SignupSuccess