import React, { useEffect, useRef, useState } from 'react'
import Header from '../../header/Header'
import "../../assets/css/myVouchers.css"
import axiosClient from '../../axios';
import moment from 'moment';
import processing from "../../assets/images/processing.png"
import useIsInViewport from '../../useIsInViewport';

function MyTransaction() {
    moment.updateLocale("en", {
        relativeTime: {
            future: "in %s",
            past: "%s ",
            s: "1s",
            m: "1m",
            mm: "%d m",
            h: "1h",
            hh: "%d h",
            d: "1d",
            dd: "%d d",
            M: "a mth",
            MM: "%d mths",
            y: "y",
            yy: "%d y",
        },
    });
    const load = useRef(null);
    const [isEnd, setIsEnd] = useState(false);

    const [transactions, setTransactions] = useState([])
    const [loader, setLoader] = useState(false)
    // const isInViewport1 = useIsInViewport(load);

    const getMyVouchers = () => {
        setLoader(true);
        axiosClient
            .get(`customers/api/payment/histories/0/30`,)
            .then((res) => {
                const transactionData = res.data.response
                setTransactions(transactionData)
                if (res.data?.response.length === 0) {
                    setIsEnd(true);
                    return;
                }

            })
            .catch((error) =>
                console.log(error)
            )
            .finally(() => setLoader(false))
    }
    // console.log("is ", isEnd, isInViewport1)
    useEffect(() => {
        getMyVouchers()
    }, [])
    // useEffect(() => {
    //     if (isInViewport1) {
    //         getMyVouchers(0)
    //     }
    // }, [isInViewport1])
    return (
        <>
            <Header expand={false} heading={"My Transactions"} />
            <div className='boxCover'>
                <div className='container'>
                    {loader ? <>
                        <div className='processing pro'>
                            <img src={processing} alt="processing" />
                        </div>
                    </> :
                        transactions.length > 0 ?
                            <>{
                                transactions?.map((ele, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className='singleVoucher'>
                                                <div className='top flex'>
                                                    <h3 className='topName'>
                                                        {ele?.p_name}
                                                    </h3>
                                                    <p>{`Rs. ${ele.amount}`}</p>

                                                </div>
                                                <div className='middle'>

                                                </div>
                                                <div className='middle'>
                                                    <p>{`Number of Vouchers. ${ele.qty}`}</p>
                                                </div>
                                                <div className='middle orderId'>
                                                    <p>{`Order ID : ${ele?.order_id}`}</p>
                                                </div>
                                                <div className='bottom flex'>
                                                    <div className='topDesc'>
                                                        <h6 className={ele?.status === "SUCCESS" ? null : "error"}>{ele?.status} <span>{moment.utc(ele.created_at).format('MMM Do YYYY ,  h:mm:ss')}</span></h6>
                                                    </div>

                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                })}

                                {/* {isEnd ? "" : (
                                <>
                                    <div className="Load_more" ref={load}>
                                        <h3>
                                            You're All caught up!
                                        </h3>
                                    </div>
                                </>
                            )} */}
                            </>
                            :

                            <p className='not' >You haven't purchased any vouhcer yet</p>
                    }

                </div>
            </div>
        </>
    )
}

export default MyTransaction