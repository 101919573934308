import React, { useState, useEffect } from "react";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material';
import processing from "../../assets/images/fail.svg"
import load from "../../assets/images/processing.png"

import right from "../../assets/images/tick.svg"

import { ReactComponent as Headphone } from "../../assets/images/headphone.svg";


import OtpInput from 'react-otp-input';

import './Signup.css'
import axiosClient from '../../axios';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { auth, provider } from "../../Firebase";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function Signup() {
    const navigate = useNavigate();
    const [isSendotp, setisSendOtp] = useState(false);
    const [otp, setOtp] = useState('')
    const [value, setValue] = useState("");
    const [name, setName] = useState('');
    const [email, setEmail] = useState('')
    const [checkbox, setCheckBox] = useState(false);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(30);
    const [errorMsg, setErrorMsg] = useState("invalid")
    const [images, setImages] = useState(processing)
    const [open, setOpen] = useState(false)
    const [Error, setError] = useState(false)
    const [loadGif, setLoadGif] = useState(false)
    const handleOpen = (i, f) => {
        setOpen(true);
        setImages(i);
        setErrorMsg(f)
        setTimeout(() => {
            handleDelete()
        }, 3000)
    }
    const handleDelete = () => setOpen(false);

    const formData = {
        "email": email,
        "mobile": value?.slice(3),
        "user_name": name,
        "name": name,
        "app_key": ""
    }
    const otpData = {
        "mobile": value?.slice(3),
        "otp": otp
    }

    const getEmail = async () => {
        try {
            setLoadGif(true);
            const result = await auth.signInWithPopup(provider);
            if (result) {
                setLoadGif(false);
                const user = result.user;
                setEmail(user.email)
            }
        } catch (error) {
            handleOpen(processing, "Some Technical Issue")

            setLoadGif(false)
        }
    }


    const handleContinue = () => {
        if (!name || !email || !value || !checkbox) {
            setError(true)
            return
        }
        if (name && value && checkbox) {
            axiosClient.post(`/customers/api/register`, formData)
                .then((res => {
                    if (res?.data?.status === true) {
                        handleOpen(right, res?.data?.message)
                        setisSendOtp(true)
                        return
                    } else {
                        handleOpen(processing, res.data.message)
                        return
                    }
                }))
                .catch((err) => console.log(err))
        }
    }
    const handleotp = async () => {
        if (otp?.length !== 6) {
            handleOpen(processing, "Please enter a Valid OTP")
            return
        }
        setLoadGif(true);
        await axiosClient.post(`/customers/api/verfiy-otp`, otpData)
            .then((res) => {
                setisSendOtp(false)
                if (res.data.jwt) {
                    localStorage.setItem("jwt", JSON.stringify(res.data.jwt))
                    handleOpen(right, "User registered successfully.")
                    setTimeout(() => window.location.replace("/"), 2000);
                    return
                } else {
                    setisSendOtp(true)
                    handleOpen(processing, res.data.message)
                    return
                }
            })
            .catch((error) => {
                console.log('error', error)
            })
            .finally(() => setLoadGif(false))

    }

    const handleResendOtp = () => {
        setSeconds(30)
        axiosClient.post(`/customers/api/resend-otp`, { "mobile": value?.slice(3) })
            .then((res) => {
                if (res?.data?.status === true) {
                    handleOpen(right, res.data.message)
                    setisSendOtp(true)
                    return
                } else {
                    handleOpen(processing, res.data.message)
                    setisSendOtp(false)
                    return
                }
            })
            .catch((error) => {
                console.log(error)
            })

    }




    useEffect(() => {
        if (isSendotp) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }
                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(interval);
                    } else {
                        setSeconds(59);
                        setMinutes(minutes - 1);
                    }
                }
            }, 1000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [isSendotp, seconds]);


    return (
        <>
            <div className="topcontainer">

                {
                    loadGif ? <>
                        <div className='processing pro'>
                            <img src={load} alt="processing" />
                        </div>
                    </> :
                        <div className="containerTwo">

                            <div className="secondbox">
                                {isSendotp ? <div className="otp_container" >
                                    <div className='flex_class_signup'>
                                        <div className="otpsignTopupxheading">TopUpX</div>

                                        <div className="bootom_flex">
                                            <div className="flex-gap-2">
                                                <div className="otp_div">
                                                    <div>Verify <span>with OTP</span></div>
                                                </div>

                                                <div className='sdfs'>
                                                    <div className="send_via_sms">
                                                        Sent via SMS to {value}
                                                    </div>
                                                    <div onClick={() => setisSendOtp(false)}>
                                                        Change number
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex-gap-2">
                                                <div>

                                                    <OtpInput
                                                        value={otp}
                                                        onChange={setOtp}
                                                        numInputs={6}
                                                        inputType="number"
                                                        renderSeparator={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                                                        renderInput={(props) => <input {...props} className="otpBox" />}
                                                    />
                                                </div>
                                                <div className='sdfs'>
                                                    <div className="auto-fill">
                                                        {seconds > 0 || minutes > 0 ? (
                                                            <p>
                                                                Wait for Resend otp: {minutes < 10 ? `0${minutes}` : minutes}:
                                                                {seconds < 10 ? `0${seconds}` : seconds}
                                                            </p>
                                                        ) : (
                                                            <p>Didn't recieve OTP?</p>
                                                        )}
                                                    </div>
                                                    <div style={{ color: (seconds > 0 || minutes > 0 ? 'rgba(38, 138, 185, 1)' : 'rgba(38, 138, 185, 1)') }}
                                                        onClick={() => {
                                                            if (seconds > 0 || minutes > 0) {
                                                                return
                                                            } else {
                                                                handleResendOtp();
                                                            }
                                                        }}>
                                                        Resend
                                                    </div>
                                                </div>


                                            </div>

                                            <div className="otp-submit-signup">
                                                <Button className="submit-otp-button-signup" onClick={() => { handleotp() }}   > Verify OTP </Button>
                                            </div>

                                            <div className="bottomDiv-sign-otp">
                                                <div>
                                                    <button type="button" className="NeedSupport" onClick={() => window.open("https://api.whatsapp.com/send?phone=918368894653", '_blank')}>
                                                        <div className="needSupportbutton">
                                                            <div><Headphone /></div><div className="needsupportfont">Need Support</div>
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className="bottom-para">
                                                    <p >© 2023 All Rights Reserved</p>
                                                </div>
                                            </div>


                                        </div>

                                    </div>


                                </div> :

                                    <div className="gapdiv">
                                        <div className="upperdiv">
                                            <div className="Topupxheading">TopUpX</div>
                                            <div className="accountnow">
                                                <div className="signupLine"><span className="span1">Signup</span><span className="getstarted">to get started</span></div>
                                                <div className="para1">Create your account now </div>
                                            </div>
                                        </div>
                                        <div className="twoinputbox">
                                            <div className="inputbox" >
                                                <input type="text" value={name} placeholder="Enter your name" onChange={(e) => { setName(e.target.value) }} />
                                                {(Error && name === "") && <p className="nameError">*Enter your name*</p>}

                                                <input type="text" value={email} placeholder="Select your email address" onClick={(e) => getEmail()} />
                                                {(Error && email === "") && <p className="emailError">*Select your Gmail*</p>}
                                            </div>

                                            <div className="selectinput">

                                                <PhoneInput
                                                    placeholder="Enter phone number"
                                                    value={value}
                                                    defaultCountry="IN"
                                                    onChange={setValue}
                                                />
                                                {(Error && value?.slice(3).length !== 10) && <p className="mobileError" >*Enter valid mobile number*</p>}
                                            </div>
                                        </div>

                                        <div className="checkboxpara">
                                            <input className="inputchekbox" type="checkbox" checked={checkbox} onChange={() => setCheckBox(!checkbox)} />
                                            <div className="para2">By continuing, I agree to the <span className="span2">Terms of use</span> & <span className="span2 span3">Privacy Policy</span></div>
                                            {(Error && !checkbox) && <p className="checkboxError part2" >*Please accept our Terms & Conditions*</p>}

                                        </div>


                                        <div className="continuebutton">
                                            <button type="button" onClick={() => handleContinue()}>Continue</button>
                                        </div>

                                        <div className="lowerparagraph">
                                            <div className="lastLine">
                                                <hr />
                                                <div className="spanlast ">
                                                    <div>Already have a account?</div>
                                                    <div className="span2" onClick={() => { navigate('/') }}>Login</div>
                                                </div>
                                            </div>
                                            <div className="signbottomDiv">
                                                <div>
                                                    <button type="button" className="NeedSupport" onClick={() => window.open("https://api.whatsapp.com/send?phone=918368894653", '_blank')}>
                                                        <div className="needSupportbutton">
                                                            <span><Headphone /></span><div>Need Support</div>
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className="bottom-para">
                                                    <p >© 2023 All Rights Reserved</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                }


                            </div>
                        </div>
                }
            </div>



            <Dialog
                open={open}
                onClose={() => handleDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox'>
                    <img src={images} alt="pendong" className='pending' />
                    <p>{errorMsg}</p>
                </div>
            </Dialog>



        </>

    )
}

export default Signup;