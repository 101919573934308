import React from 'react'
import { useState } from 'react';
import Header from '../../header/Header'
import edits from '../../assets/images/Vector.svg'
import axiosClient from '../../axios';

function MyProfile() {
    const userInfo = JSON.parse(localStorage.getItem("User_Info"));
    const [editable, setEditable] = useState(false)
    const [name, setName] = useState(userInfo?.name);
    const editProfile = () => {
        const trimmedName = name.trim();
        if (trimmedName.length < 1) {
            alert("Name can not be empty.")
            return
        }
        if (trimmedName.length < 2) {
            alert("Name must contain atleast 2 letters.")
            return
        }
        axiosClient.put("customers/api/edit/profile", {
            profile_img: userInfo?.name.slice(0, 1),
            name
        }).then((res) => {
            let data = res.data
            if (data.status_code === 200) {
                userInfo.name = name
                localStorage.setItem("User_Info", JSON.stringify(userInfo))
                alert("Profile has been edited successfully.")
                setEditable(false)
            }
        })
            .catch((err) => console.log(err))
    }

    return (
        <>
            <Header expand={false} />
            <div className='boxCover'>
                <div className='navContainer'>
                    <div className='proflieDisplay'>
                        <div className='imageBox'>
                            <p>{userInfo?.name.slice(0, 1)}</p>

                        </div>
                        <h3 className='name'>{userInfo?.name}</h3>
                    </div>
                    <div className='inputBox'>
                        <p>Name</p>
                        <div className="pRelative">
                            <input
                                id="name"
                                type="text"
                                className="profileInput"
                                value={name}
                                // disabled={!editable}
                                disabled
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (/^[a-zA-Z0-9]*$/.test(inputValue) && inputValue.length <= 20) {
                                        setName(inputValue);
                                    }
                                }}
                            />
                            {
                                editable ? <>
                                    <img src={edits} alt="editIcon" className='editIcon' />
                                </>
                                    : null
                            }

                        </div>

                    </div>
                    <div className='inputBox'>
                        <p>Email</p>
                        <div className="pRelative">
                            <input
                                id="name"
                                type="text"
                                className="profileInput"
                                value={userInfo?.email}
                                disabled
                            />
                        </div>

                    </div>
                    {/* 
                    {
                        editable ?
                            <>
                                <div className='edit' onClick={() => editProfile()}>
                                    Submit
                                </div>
                            </>
                            : <>
                                <div className='edit' onClick={() => setEditable(true)}>
                                    Edit
                                </div>
                            </>

                    }
 */}
                </div>
            </div>
        </>
    )
}

export default MyProfile