import React from 'react'
import './LoginSuccess.css'

const LoginSuccess = () => {
  return (
    <div className="loginSuccessContainer">
        <div className="divContainer">
        <div className="login_1_heading">TopUpX</div>
        <div>
            <div className="div1">You have successfully logged in</div>
            <div className="div1">your account!</div>
        </div>
        </div>
    </div>
  )
}

export default LoginSuccess