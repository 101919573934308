import React, { useEffect, useRef, useState } from 'react'
import phonePe from "../../assets/images/phonePe.svg"
import pending from "../../assets/images/pending.svg"
import processing from "../../assets/images/processing.png"
import tick from "../../assets/images/tick.svg"
import fail from "../../assets/images/fail.svg"
import wallet from "../../assets/images/wallet.png"
import Header from '../../header/Header'
import axiosClient from '../../axios';
import { ReactComponent as Ellipse } from "../../assets/images/ellipse.svg"
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import axios from 'axios'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Payment() {

    const finalVoucher = JSON.parse(localStorage.getItem("finalVoucher"));
    const User_Info = JSON.parse(localStorage.getItem("User_Info"));
    const [walletactive, setWalletActive] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState("");
    const [process, setProcess] = useState(false)
    const [remaining, setRemaining] = useState(0);
    const [ffWallet, setFfWallet] = useState(0);
    const [open, setOpen] = useState(false);
    const [blow, setBlow] = useState(false);
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState("")
    const [images, setImages] = useState()
    const startPayment = async () => {
        console.log('payment', paymentMethod)
        if (walletactive && ffWallet < 1) {
            handleOpen(pending, "You must have atleast Rs.1 in your wallet so please select only Phonepe to continue.")
            return
        }
        else if (walletactive && remaining == 0) {
            setPaymentMethod("")
            freecashstartPayment();
        } else if (walletactive && remaining > 0) {
            finalVoucher.is_free_cash = true
            if (!paymentMethod) {
                handleOpen(fail, "You have insufficient balance in your wallet, please select Phonepe to continue")
            } else if (paymentMethod === "PhonePe") {
                phonePePayment();
            }
        } else if (!walletactive && !paymentMethod) {
            handleOpen(pending, "Select Payment mode to continue")
        } else if (!walletactive && paymentMethod) {
            finalVoucher.is_free_cash = false
            if (paymentMethod === "PhonePe") {
                phonePePayment();
            } else if (paymentMethod === "SP") {
                spayInitiate();
            }
        }
    }

    const phonePePayment = () => {
        setProcess(true)
        axiosClient
            .post("/customers/api/phone-pe/start/payment", finalVoucher)
            .then((res) => {
                if (res.data.status_code === 200) {
                    const transactionData = res?.data?.response?.data
                    const link = transactionData.instrumentResponse.redirectInfo.url;
                    window.open(link, "_self")
                } else {
                    setProcess(false)
                    handleOpen(pending, res.data.message)
                }
            })
            .catch((error) => {
                handleOpen(pending, "Some technical Error")
            }).finally(() => setProcess(false))
    }


    const spayInitiate = async () => {

        let verify = {
            public_key: "Njc0MF82XzIwMjMwNDA2MTMzNjU3",
            terNO: 6,
            bill_ip: "",
            source_url: "https://gamerpe.com/",
            bill_amt: 10.00,
            product_name: "Testing Product ",
            fullname: "Test Full Name",
            bill_email: "test.4350@test.com",
            bill_phone: "9870654455",
            reference: "2312022823120228"
        }
        axios.post("https://pg.spay.live/checkout", verify)
            .then(res => console.log(res))
            .catch(err => console.log(err))
    }

    const fetchWallet = () => {
        setProcess(true)
        axiosClient
            .get("/customers/api/free/cash/wallet")
            .then((res) => {
                if (res.status === 200) {
                    setFfWallet(res.data.response.amount)
                    let leftAmount = Number(finalVoucher?.price * finalVoucher?.qty) - Number(res.data.response.amount)
                    Number(res.data.response.amount) > 1 ? setWalletActive(true) : setWalletActive(false)
                    Number(leftAmount) >= 1 ? setRemaining(Math.ceil(leftAmount)) : Number(leftAmount) <= 0 ? setRemaining(0) : setRemaining(1);
                    // leftAmount >= 1 ? setPaymentMethod("PhonePe") : setPaymentMethod("")
                    setPaymentMethod("PhonePe")
                } else {
                    handleOpen(pending, res.data.message)
                }
            })
            .catch((error) =>
                console.log(error)
            ).finally(() => setProcess(false))
    }
    const freecashstartPayment = async () => {
        setProcess(true)
        await axiosClient
            .post("/customers/api/free-cash/start/payment", finalVoucher)
            .then((res) => {
                if (res.data.status_code === 200) {
                    let txnId = res.data.response.transaction_id
                    try {
                        axiosClient.get(`/customers/api/phone-pe/payment/${txnId}/status`)
                            .then((response) => {
                                if (response.data.status_code === 200) {
                                    finalVoucher.transaction_id = txnId
                                    axiosClient.post("/customers/api/voucher/order", finalVoucher)
                                        .then((res) => {
                                            setBlow(true)
                                            handleOpen(tick, "Vouhcer Redeemed Successfully", true)
                                            setProcess(false)
                                            localStorage.removeItem("finalVoucher");
                                            localStorage.removeItem("voucherSelected");
                                            // navigate("/")
                                        })
                                        .catch((error) => {
                                            setProcess(false)
                                            handleOpen(fail, response.data.message)
                                        }
                                        );
                                } else {
                                    setProcess(false)
                                    handleOpen(fail, response.data.message)
                                }
                            })
                            .catch((error) => {
                                setProcess(false)
                                handleOpen(fail, "Some technical error.\nPlease Try again after sometime")
                            }
                            );
                    } catch (error) {
                        setProcess(false)
                        handleOpen(pending, "Some technical error.\nPlease Try again after sometime")
                    }
                } else {
                    setProcess(false)
                    handleOpen(pending, res.data.message)
                }
            })
            .catch((error) => {
                setProcess(false)
                handleOpen(pending, "Some Technical Issue.\n Please try again later.")
            }
            );
    }
    const timeoutIdRef = useRef(null);
    const handleOpen = (i, f, g) => {
        setOpen(true);
        setImages(i);
        setErrorMsg(f)
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
        }
        timeoutIdRef.current = setTimeout(() => {
            handleDelete(g);
        }, 3000);
    }
    const handleDelete = (e) => {
        setOpen(false);
        clearTimeout(timeoutIdRef.current);
        if (blow || e) {
            navigate("/", { replace: true });
        }
    }


    useEffect(() => {
        fetchWallet();
    }, [])
    return (
        <>
            <Header expand={false} payment={true} newClass={true} heading={"Payment"} />
            {process ?
                <>
                    <div className='processing pro'>
                        <img src={processing} alt="processing" />
                    </div></> : <>
                    <div className='vouchercontainer'>
                        {User_Info.is_via_default_publisher == false && ffWallet > 1 ? <div className="gateways dFlex" >
                            <div className='dFlex align'>
                                <div className='app_Pic'>
                                    <img src={wallet} alt="Wallet" />
                                </div>
                                <p className='paymentName'>Prorewards Wallet</p>
                            </div>
                            <div className='circle'>
                                {walletactive ? <Ellipse /> : ""}
                            </div>
                            {
                                walletactive ? <>
                                    <div className='voucherDetailCover'>
                                        <div className='detailBox'>
                                            <p>Wallet Balance</p>
                                            <p>{ffWallet ? Number(ffWallet).toFixed(2) : 0}</p>
                                        </div>
                                        <div className='detailBox'>
                                            <p>Voucher Amount</p>
                                            <p>{finalVoucher?.price * finalVoucher?.qty}</p>
                                        </div>
                                        <div className='detailBox'>
                                            <p>Remaining Amount</p>
                                            <p>{Number(remaining).toFixed(2)}</p>
                                        </div>
                                    </div>

                                </> : null
                            }

                        </div> : ""}

                        {remaining > 0 ?
                            <div className="gateways dFlex">
                                <div className='dFlex align'>
                                    <div className='app_Pic'>
                                        <img src={phonePe} alt="PhonePe" />
                                    </div>
                                    <p className='paymentName'>PhonePe</p>
                                </div>
                                <div className='circle'>
                                    {paymentMethod === "PhonePe" ? <Ellipse /> : ""}
                                </div>
                            </div> : ""}

                        {/* 
                        <div className="gateways dFlex" onClick={() => setPaymentMethod("SP")}>
                            <div className='dFlex align'>
                                <div className='app_Pic'>
                                    <img src={phonePe} alt="PhonePe" />
                                </div>
                                <p className='paymentName'>Spay</p>
                            </div>
                            <div className='circle'>
                                {paymentMethod === "SP" ? <Ellipse /> : ""}
                            </div>
                        </div> */}

                        <div className='proceedButton' onClick={() => startPayment()}>
                            <div className='payBox' >
                                Complete Purchase
                            </div>
                        </div>
                    </div>
                </>

            }

            <Dialog
                open={open}
                onClose={() => handleDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox'>
                    <img src={images} alt="pendong" className='pending' />
                    <p>{errorMsg}</p>
                </div>
            </Dialog>
        </>
    )
}

export default Payment