import React, { useEffect, useState } from 'react'
import Header from '../../header/Header'
import Category from '../Categories/Category'
import { ReactComponent as Personal } from "../../assets/images/personal.svg"
import { ReactComponent as MyVouhcer } from "../../assets/images/myVouhcer.svg"
import { ReactComponent as MyTransaction } from "../../assets/images/myTransaction.svg"
import { useNavigate } from 'react-router-dom'
import axiosClient from '../../axios';

function Home() {
    const navigate = useNavigate();
    const jwtToken = JSON.parse(localStorage.getItem("jwt"));
    const [updatedWallet, setUpdatedWallet] = useState(0)
    // stop going back in page
    const handledisablegoingback = () => {
        window.history.pushState(null, document.title, window.location.href);
    }
    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', handledisablegoingback);

        return () => window.removeEventListener('popstate', handledisablegoingback)
    }, [])
    useEffect(() => {
        if (window.location.pathname === '/') {
            window.history.replaceState(null, null, '/');
        }
        const profileHandle = async () => {
            await axiosClient.get(`/customers/api/profile`)
                .then((res) => {
                    if (res.data.status === true) {
                        localStorage.setItem("User_Info", JSON.stringify(res?.data?.response))
                        localStorage.setItem("coins", JSON.stringify(res.data.response?.wallet))
                        setUpdatedWallet(res.data.response?.wallet)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        profileHandle()

        if (!jwtToken) {
            window.location.reload()
        }
    }, [])

    return (
        <>
            <Header expand={true} walletCoin={updatedWallet} />
            <div className='boxCover prelative'>
                <Category />
                <div className='footer'>
                    <div className='footerBox' onClick={() => navigate('/mytransactions')}>
                        <MyTransaction />
                        <h6>My Transactions</h6>
                    </div>
                    <div className='footerBox' onClick={() => navigate('/myVouchers')}>
                        <MyVouhcer />
                        <h6>My Vouchers</h6>
                    </div>
                    <div className='footerBox' onClick={() => navigate('/myProfile')}>
                        <Personal />
                        <h6>My Profile</h6>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Home