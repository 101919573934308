import React, { useEffect, useState } from "react";
import { auth, provider } from "../../Firebase";

import { ReactComponent as Headphone } from "../../assets/images/headphone.svg";
import PhoneInput from 'react-phone-number-input'
import processings from "../../assets/images/processing.png"

import "./Login.css"
import axiosClient from '../../axios';
import { useLocation, useNavigate } from "react-router-dom";
import processing from "../../assets/images/fail.svg"
import mail_Login from "../../assets/images/mailLogin.png"
import { Button } from "@mui/material";
import OtpInput from 'react-otp-input';
import right from "../../assets/images/tick.svg"
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function Login() {
    const navigate = useNavigate();
    const [process, setProcess] = useState(false);
    const userLogin = {
        app_key: "",
    }
    const search = useLocation().search;
    const emailId = new URLSearchParams(search).get("emailId");
    const name = new URLSearchParams(search).get("name");
    const publisher_id = new URLSearchParams(search).get("publisher_id");
    const p_user_id = new URLSearchParams(search).get("p_user_id");
    const p_number = new URLSearchParams(search).get("p_number");
    const [mobile, setmobile] = useState("");
    const [naviBar, setNaviBar] = useState()
    const [isError, setIsError] = useState(false);
    const [isSendOtp, setisSendOtp] = useState(false);
    const [otp, setOtp] = useState('')
    const [errorMsg, setErrorMsg] = useState("invalid")
    const [images, setImages] = useState(processing);

    const [open, setOpen] = useState(false)
    const handleDelete = (k) => {
        setOpen(false);
        if (k || naviBar == "main") {
            window.location.replace("/")
        }
    }
    const handleOpen = (i, f, g) => {
        setOpen(true);
        setImages(i);
        setErrorMsg(f)
        setTimeout(() => {
            if (g) {
                handleDelete(true)
                return
            } else {
                handleDelete()
                return
            }

        }, 3000)
    }




    const formData = {
        "mobile": mobile?.slice(3)
    }

    const otpData = {
        "mobile": mobile?.slice(3),
        "otp": otp
    }


    const handleRequestOtp = async () => {
        if (mobile?.slice(3)?.length !== 10) {
            return setIsError(true)
        }

        await axiosClient.post(`/customers/api/login`, formData)
            .then((res) => {
                if (res?.data?.status === true) {
                    console.log("response comming successfully", res)
                    handleOpen(right, res?.data?.message)
                    setisSendOtp(true)

                }
                else {
                    console.log("response is not comming successfully", res)

                    handleOpen(processing, res.data.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const handleotp = async () => {
        if (otp?.length !== 6) {
            handleOpen(processing, "Please enter a valid OTP")
            return
        }
        await axiosClient.post(`/customers/api/verfiy-otp`, otpData)
            .then(async (res) => {
                if (res.data.jwt) {
                    localStorage.setItem("jwt", JSON.stringify(res.data.jwt))
                    setNaviBar("main")
                    handleOpen(right, "User login successfully", true)
                } else {
                    handleOpen(processing, "Please enter a valid OTP")
                    return
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    const handleResendOtp = () => {
        setSeconds(30)
        axiosClient.post(`/customers/api/resend-otp`, { "mobile": mobile.slice(3) })
            .then((res) => {
                if (res?.data?.status === true) {
                    handleOpen(right, res?.data?.message)
                    setisSendOtp(true)
                    return
                } else {
                    handleOpen(processing, res?.data?.message)
                    setisSendOtp(false)
                    return
                }
            })
            .catch((error) => {
                console.log(error)

            })

    }









    const joinUser = async (e, f, g, h, i) => {
        setProcess(true);
        const userInfo = {
            name: f,
            user_name: f,
            email: e,
            profile_img: "",
            app_key: h,
            p_user_id: i,
            mobile: g
        }
        localStorage.setItem("User_Info", JSON.stringify(userInfo))
        await axiosClient
            .post("/customers/api/login", userInfo)
            .then((res) => {
                console.log("res", res)
                if (res.data.jwt === undefined || res.data.jwt === null || res.data.jwt === "") {
                    localStorage.clear()
                    window.location.replace("/");
                } else {
                    localStorage.setItem("jwt", JSON.stringify(res.data.jwt))
                    localStorage.setItem("coins", JSON.stringify(res.data.response.pro_reward_amount))
                    window.location.replace("/");
                }
            })
            .catch((error) => {
                localStorage.clear()
                window.location.reload();
            }
            ).finally(() => setProcess(false))
    };
    const signin = async () => {

        setProcess(true);
        await auth
            .signInWithPopup(provider)
            .then((result) => {
                const user = result.user;
                localStorage.setItem("SET_USER", JSON.stringify(user))
                const userInfo = {
                    name: result.user.displayName,
                    user_name: result.user.displayName,
                    email: result.user.email,
                    profile_img: result.user.photoURL,
                    app_key: userLogin.app_key,
                }
                localStorage.setItem("User_Info", JSON.stringify(userInfo))
                axiosClient
                    .post("/customers/api/login-with-gmail", {
                        email: result.user.email,
                    })
                    .then((res) => {
                        if (res.data.jwt) {
                            localStorage.setItem("jwt", JSON.stringify(res.data.jwt))
                            window.location.replace("/");
                        } else {
                            handleOpen(processing, res.data.message)
                        }

                    })
                    .catch((error) => {
                        localStorage.clear()
                        window.location.reload();
                    }
                    )
            })
            .catch((error) => {
                console.log(error)
            }).finally(() => setProcess(false));

    };
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(30);
    useEffect(() => {
        if (name && p_number && emailId) {
            joinUser(emailId, name, p_number, publisher_id, p_user_id);
        }
        // setProcess(true);
        // if (name && profilePic && emailId) {
        //     const timer = setTimeout(() => {
        //         joinUser(emailId, name, profilePic, publisher_id, p_user_id);
        //     }, 2000);
        //     return () => clearTimeout(timer);
        // } else {
        //     const timer = setTimeout(() => {
        //         setProcess(false);
        //     }, 1000);
        //     return () => clearTimeout(timer);

        // }
    }, [name, p_number, emailId, p_user_id]);
    useEffect(() => {
        if (isSendOtp) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }
                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(interval);
                    } else {
                        setSeconds(59);
                        setMinutes(minutes - 1);
                    }
                }
            }, 1000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [isSendOtp, seconds]);

    return (
        <>
            {process ? <>
                <div className='processing pro'>
                    <img src={processings} alt="processing" />
                </div></> : <>
                <div className="login">
                    <div>
                        <h1 className="top__heading">TopUpx</h1>
                    </div>

                    {isSendOtp ?

                        <div className='flex_class '>
                            <div className="flex-gap-2-login ">
                                <div className="otp_div part2">
                                    <div className="login-verify-otp-text">Verify <span>with OTP</span></div>
                                </div>

                                <div className='sdfs-login'>
                                    <div className="send_via_sms-login">
                                        Sent via SMS to {mobile}
                                    </div>
                                    <div onClick={() => { setisSendOtp(false) }}>
                                        Change number
                                    </div>
                                </div>
                            </div>

                            <div className="flex-gap-2-login login-otp-box">
                                <div>

                                    <OtpInput
                                        value={otp}
                                        onChange={setOtp}
                                        numInputs={6}
                                        inputType="number"
                                        renderSeparator={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                                        renderInput={(props) => <input {...props} className="otpBox" />}
                                    />
                                </div>
                                <div className='sdfs-login'>
                                    <div className="auto-fill-login-otp">
                                        {seconds > 0 || minutes > 0 ? (
                                            <p>
                                                Wait for Resend otp: {minutes < 10 ? `0${minutes}` : minutes}:
                                                {seconds < 10 ? `0${seconds}` : seconds}
                                            </p>
                                        ) : (
                                            <p>Didn't recieve OTP?</p>
                                        )}
                                    </div>
                                    <div style={{ color: (seconds > 0 || minutes > 0 ? 'rgba(38, 138, 185, 1)' : 'rgba(38, 138, 185, 1)') }}
                                        onClick={() => {
                                            if (seconds > 0 || minutes > 0) {
                                                return
                                            } else {
                                                handleResendOtp();
                                            }
                                        }}>
                                        Resend
                                    </div>
                                </div>
                            </div>

                            <div className="otp-submit part0">
                                <Button className="submit-otp-button small" onClick={() => { handleotp() }}   > Verify OTP </Button>
                            </div>

                        </div>

                        :
                        <div className="login_logo">

                            <div className="login__heading">
                                <h3 className="login__to">Login <span>to your account</span></h3>
                                <p className="login__para">Welcome to TopUpX </p>
                            </div>

                            <div className="selectinput rel">

                                <PhoneInput
                                    className="mobile-input-box"
                                    placeholder="Enter phone number"
                                    value={mobile}
                                    limitMaxLength={10}
                                    defaultCountry="IN"
                                    onChange={setmobile}
                                />
                                {isError && mobile?.slice(3).length !== 10 ? <div className='errorMessage logE'>*Please enter a registered mobile number*</div> : null}
                            </div>

                            <div className="sent-otp" onClick={() => { handleRequestOtp() }}>
                                <Button className="sent-otp-button" >Request OTP</Button>
                            </div>

                            <div className="or_p">
                                <p>OR</p>
                            </div>
                            <div className="mail_login"><img src={mail_Login} alt="mailLogin" onClick={() => signin()} /></div>
                            <div className="not-registered-p">
                                <p className="para-one">Not registered yet? </p>
                                <p className="para-two" onClick={() => navigate('/signup')}>Create an account</p>
                            </div>



                        </div>
                    }

                    <div className="loginbottomDiv">

                        <div>
                            <button type="button" className="NeedSupport" onClick={() => window.open("https://api.whatsapp.com/send?phone=918368894653", '_blank')}>
                                <div className="needSupportbutton">
                                    <div><Headphone /></div><div>Need Support</div>
                                </div>
                            </button>
                        </div>

                        <div className="bottom-para">
                            <p >© 2023 All Rights Reserved</p>
                        </div>

                    </div>
                </div>

            </>}
            <Dialog
                open={open}
                onClose={() => handleDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox'>
                    <img src={images} alt="pendong" className='pending' />
                    <p>{errorMsg}</p>
                </div>
            </Dialog>

        </>
    );
}
export default Login