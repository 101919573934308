import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAxQ9QXw28sEkn8yD_WWVGxbdMqgvjwWbI",
    authDomain: "topupx-ed823.firebaseapp.com",
    projectId: "topupx-ed823",
    storageBucket: "topupx-ed823.appspot.com",
    messagingSenderId: "312599353652",
    appId: "1:312599353652:web:b6df69a81558fc0d596d61",
    measurementId: "G-MQQ8GJJ2VL"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({
    prompt: "select_account",
});
export { auth, provider };
