
import axios from "axios";

const axiosClient = axios.create();
const jwt = JSON.parse(localStorage.getItem("jwt"));

axiosClient.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT
axiosClient.defaults.headers = {
    authorization: `${jwt}`,
};

axiosClient.interceptors.response.use(
    (config) => {
        if (config.data.status_code === 401) {
            localStorage.clear();
            window.location.reload();
        }
        return config;
    },
    (error) => {
        if (error) {
            localStorage.clear();
            window.location.reload();
        }
        return Promise.reject(error);
    }
);
export default axiosClient;
