import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import axiosClient from '../../axios';
import Header from '../../header/Header';
import coin from "../../assets/images/rupees.jpeg"
import moment from 'moment'
import processing from "../../assets/images/processing.png"

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Voucher() {
    moment.updateLocale("en", {
        relativeTime: {
            future: "in %s",
            past: "%s ",
            s: "1s",
            m: "1m",
            mm: "%d m",
            h: "1h",
            hh: "%d h",
            d: "1d",
            dd: "%d d",
            M: "a mth",
            MM: "%d mths",
            y: "y",
            yy: "%d y",
        },
    });
    const coins = JSON.parse(localStorage.getItem("coins")) || 0


    const [activeVoucher, setActiveVoucher] = useState(0)
    const [amount, setAmount] = useState(0);
    const [remain, setRemain] = useState(0);
    const params = useParams();
    const [loader, setLoader] = useState(false)

    const [voucher, setVoucher] = useState();
    const [open, setOpen] = useState(false);
    const [quantity, setQuantity] = useState(1)
    const navigate = useNavigate()
    const VoucherDetails = () => {
        const data = voucher
        data.price = amount
        data.qty = quantity
        localStorage.setItem("voucherSelected", JSON.stringify(data))
        navigate("/confirm");
    }

    // close the dialog box
    const handleDelete = () => {
        setOpen(false)
    }

    const remainAmount = (e, f) => {
        let amt = (Number(e) * f) - Number(coins)
        console.log("quantity", f)
        if (amt < 1) {
            setRemain(1)
        } else {
            setRemain(amt)

        }
    }
    // get the particular voucher detial
    const getVouchers = () => {
        setLoader(true);
        axiosClient.get(`/customers/api/voucher/detail/${params?.id}`)
            .then((res) => {
                setVoucher(res.data.response);
                setAmount(res.data.response?.price?.denominations[0]);
                remainAmount(res.data.response?.price?.denominations[0], quantity)
            })
            .catch((err) => console.log(err))
            .finally(() => setLoader(false))
    }


    useEffect(() => {
        getVouchers();
    }, [])
    return (
        <>
            <Header expand={false} />
            {loader ? <>
                <div className='processing pro'>
                    <img src={processing} alt="processing" />
                </div></> :
                <div className='vouchercontainer'>
                    <div className='voucherBox dFlex' >
                        <div className='w50 widthFit'>
                            <div className='imageCover'>
                                <img src={voucher?.images?.mobile} alt="voucherImage" />
                            </div>

                        </div>

                        <div className='w50'>
                            <h4 className='vName'>{`${voucher?.name}`}</h4>
                        </div>
                    </div>

                    <div className='voucherBox dFlex' >
                        <div className='w70'>
                            <p className='vName'>{`Rs : ${amount}`}</p>
                            <p className='vName colorBlend'>Expiry Date : {!moment(voucher?.expiry, moment.ISO_8601, true).isValid() ? voucher?.expiry : moment.utc(voucher?.expiry).format("MMM Do YYYY")}</p>
                        </div>
                        <div className='w30'>
                            <div className='coins b0'>
                                <img src={coin} alt="coin" className='coinImg' />
                                <span>{`${amount}`}</span>
                            </div>
                        </div>
                    </div>

                    <div className='voucherBox p15'>
                        <div className='flex alignSpace'>
                            <h2 className='valueHeading'>Voucher Values</h2>
                            <p className='viewAllOption' onClick={() => setOpen(true)}>View All</p>
                        </div>


                        <div className='valueGrid'>
                            {
                                voucher?.price?.denominations.map((ele, i) => {
                                    return (
                                        <React.Fragment key={i} >
                                            <div className={activeVoucher === i ? "value active" : "value"} onClick={() => { setActiveVoucher(i); setAmount(ele); remainAmount(ele, quantity); }}>
                                                <p>{`Rs. ${ele}`}</p>
                                            </div>
                                        </React.Fragment >
                                    )
                                })
                            }


                        </div>
                    </div>
                    <div className='voucherBox p15 pb15'>
                        <div className='flex alignSpace'>
                            <h2 className='valueHeading'>Quantity</h2>
                            <div className='quantity'>
                                <span onClick={quantity > 1 ? () => { setQuantity(quantity - 1); remainAmount(amount, quantity - 1); } : () => { }}>-</span>
                                <span>{quantity}</span>
                                <span onClick={quantity < 10 ? () => { setQuantity(quantity + 1); remainAmount(amount, quantity + 1) } : () => { }}>+</span>
                            </div>
                        </div>
                    </div>
                    <div className='voucherBox p15'>
                        <p className={amount * quantity > coins ? 'warnPara' : 'greenPara'}>You have a total of&nbsp;
                            {Number(coins).toFixed(2)} Rs. to purchase gift card of Rs. {amount * quantity} you will need to pay Rs. {
                                amount * quantity > coins ? Math.ceil(remain) : 0
                            }</p>
                    </div>
                    <div className='proceedButton'>
                        <div className='payBox' onClick={() => (VoucherDetails())}>
                            Proceed to Pay
                        </div>
                    </div>
                </div>
            }
            <Dialog
                open={open}
                onClose={() => handleDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='viewAllPrice'>
                    <h4 className='viewALlPriceHeading'>Vouchers Values</h4>
                    <div className='vieALlValue'>
                        {
                            voucher?.price?.denominations.map((ele, i) => {
                                return (
                                    <React.Fragment key={i} >
                                        <div className={activeVoucher === i ? "value active" : "value"} onClick={() => { setActiveVoucher(i); setAmount(ele) }}>
                                            <p>{`Rs. ${ele}`}</p>
                                        </div>
                                    </React.Fragment >
                                )
                            })
                        }
                    </div>

                    <h6 className='doneButton' onClick={() => handleDelete()}>Done</h6>
                </div>
            </Dialog>
        </>
    )
}

export default Voucher