import React, { useEffect, useState } from 'react'
import { ReactComponent as Right } from "../../assets/images/right.svg"
import Header from '../../header/Header';
import moment from 'moment'
import { useNavigate } from 'react-router-dom';

function Confirmation() {
    moment.updateLocale("en", {
        relativeTime: {
            future: "in %s",
            past: "%s ",
            s: "1s",
            m: "1m",
            mm: "%d m",
            h: "1h",
            hh: "%d h",
            d: "1d",
            dd: "%d d",
            M: "a mth",
            MM: "%d mths",
            y: "y",
            yy: "%d y",
        },
    });
    const voucherSelected = JSON.parse(localStorage.getItem("voucherSelected"));

    const userInfo = JSON.parse(localStorage.getItem("User_Info"));
    const [emailOrder, setEmailOrder] = useState(userInfo?.email)
    const [mobile, setMobile] = useState(userInfo?.mobile)
    const [emailError, setEmailError] = useState(false);
    const [mobileError, setMobileError] = useState(false);
    const [showDesc, setShowDesc] = useState(true);
    const [showDetail, setShowDetail] = useState(true);
    const finalVoucher = {
        product_id: voucherSelected._id,
        email: emailOrder,
        sku: voucherSelected.sku,
        qty: voucherSelected.qty,
        price: voucherSelected.price,
        mobile: '+91' + mobile,
        is_free_cash: false,
        category_type: voucherSelected.category_type
    }

    const navigate = useNavigate();
    const proceedPayment = () => {
        if (!emailOrder) {
            setEmailError(true);
        } else if (!mobile) {
            setMobileError(true);
        } else if (mobile.length < 10) {
            setMobileError(true);
        } else {
            navigate("/payment");
            localStorage.setItem("finalVoucher", JSON.stringify(finalVoucher))
        }
    }

    useEffect(() => {
        if (!voucherSelected || !userInfo) {
            navigate("/")
        }
    }, [])
    return (
        <>
            <Header expand={false} newClass={true} heading={voucherSelected?.name} />
            <div className='vouchercontainer'>
                <div className='voucherNew'>
                    <p>Rs. {voucherSelected.price}</p>
                    <p>Quantity: {voucherSelected.qty}</p>
                </div>
                <div className='voucherBox dFlex' >
                    <div className='w50 widthFit'>
                        <div className='imageCover'>
                            <img src={voucherSelected?.images?.mobile} alt="voucherImage" />
                        </div>

                    </div>

                    <div className='w50'>
                        <div>

                            <h4 className='vName'>{voucherSelected?.name}</h4>
                            <p>Validity : {!moment(voucherSelected?.expiry, moment.ISO_8601, true).isValid() ? voucherSelected?.expiry : moment.utc(voucherSelected?.expiry).format("MMM Do YYYY")}</p>
                        </div>
                    </div>
                    <div className='w100'>
                        <p>
                            Enter your email to receive vouhcer details
                        </p>
                        <input
                            type="email"
                            name="email"
                            onChange={(e) => {
                                setEmailOrder(e.target.value);
                                setEmailError(false);
                            }}
                            value={emailOrder}
                            required
                            className="newinputTag"
                            placeholder='Email'
                        />
                        {emailError ? <p className='errorMsg'>Email is Necessary</p> : null}

                    </div>
                    <div className='w100'>
                        <p>
                            Enter your Mobile Number
                        </p>
                        <input
                            type="number"
                            name="number"
                            onChange={(e) => {
                                if (e.target.value.length <= 10) {
                                    setMobile(e.target.value)
                                    setMobileError(false);
                                }
                            }}
                            value={mobile}
                            required
                            className="newinputTag"
                            placeholder='Mobile Number'
                            pattern="\d*" maxLength={10}
                        />
                        {mobileError ? <p className='errorMsg'>Mobile Number is Necessary</p> : null}

                    </div>
                </div>

                {/*------- details--------- */}
                <div className='voucherBox p15'>
                    <div className='flex alignSpace' onClick={() => setShowDetail((e) => !e)}>
                        <h2 className="valueHeading">Details</h2>
                        <span className={`span ${showDetail ? "active" : ""}`} >
                            <Right />
                        </span>
                    </div>
                    {
                        showDetail ?

                            <div className='voucherDetailCover'>
                                <div className='detailBox'>
                                    <p>Price</p>
                                    <p>{voucherSelected.price}</p>
                                </div>
                                <div className='detailBox'>
                                    <p>Quantity</p>
                                    <p>{voucherSelected.qty}</p>
                                </div>
                                <div className='detailBox'>
                                    <p>Amount To Pay</p>
                                    <p>{voucherSelected.price * voucherSelected.qty}</p>
                                </div>
                            </div> : null
                    }

                </div>



                {/*------- description--------- */}
                <div className='voucherBox p15 '>
                    <div className='flex alignSpace' onClick={() => setShowDesc((e) => !e)}>
                        <h2 className="valueHeading">Voucher Description</h2>
                        <span className={`span ${showDesc ? "" : "active"}`} >
                            <Right />
                        </span>
                    </div>

                    {
                        !showDesc ?
                            <>
                                <div className='voucherDescCover'>
                                    <p className='vName' dangerouslySetInnerHTML={{ __html: voucherSelected?.description }}></p>
                                </div></> : null
                    }

                </div>

                <div className='proceedButton'>
                    <div className='payBox' onClick={() => proceedPayment()}>
                        Proceed to Pay
                    </div>
                </div>
            </div>
        </>
    )
}
export default Confirmation