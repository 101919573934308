import { useEffect } from 'react';

const DisableBackButton = () => {
    const handledisablegoingback = () => {
        window.history.pushState(null, document.title, window.location.href);
    };

    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', handledisablegoingback);

        return () => window.removeEventListener('popstate', handledisablegoingback);
    }, []);

    // Check the current URL and conditionally render the component
    const isDisableBackButtonPage = window.location.pathname === '/disable-back-button';

    if (!isDisableBackButtonPage) {
        return null; // Do not apply the functionality on other pages
    }

    return null; // Render your component's content here
};

export default DisableBackButton;
