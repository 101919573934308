import React, { useState } from 'react'
import Header from '../../header/Header'
import "../../assets/css/Nav.css"
import { useNavigate } from 'react-router-dom';
import right from "../../assets/images/tick.svg"

import { ReactComponent as Personal } from "../../assets/images/personal.svg"
import { ReactComponent as MyVouhcer } from "../../assets/images/myVouhcer.svg"
import { ReactComponent as MyTransaction } from "../../assets/images/myTransaction.svg"
import { ReactComponent as MyLogout } from "../../assets/images/myLogout.svg"
import Privacy from "../../assets/images/privacyLogo.png"
import { ReactComponent as More } from "../../assets/images/more.svg"
import axiosClient from '../../axios';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function NavBar() {
    const userInfo = JSON.parse(localStorage.getItem("User_Info"));
    const navigate = useNavigate();
    const [open, setOpen] = useState(false)
    const logout = () => {
        axiosClient.post("/customers/api/logout").then((res) => {
            localStorage.clear();
            window.location.reload();
        }).catch((err) => console.log(err))

    }
    const handleDelete = () => {
        setOpen(false)
    }
    return (
        <>
            <Header expand={false} />
            <div className='boxCover'>
                <div className='navContainer'>
                    <div className='proflieDisplay'>
                        <div className='imageBox'>
                            <p>{userInfo?.name.slice(0, 1)}</p>
                            {/* <p>n</p>     */}
                        </div>
                        <h3 className='name'>{userInfo?.name}</h3>
                        {/* <h3 className='name'>Naman</h3> */}
                    </div>
                    <div className='navigationBox' onClick={() => navigate('/myProfile')}>
                        <div>
                            <Personal />
                            <h2>My Profile</h2>
                        </div>
                        <More />
                    </div>
                    <div className='navigationBox' onClick={() => navigate('/myVouchers')}>
                        <div>
                            <MyVouhcer />
                            <h2>My Vouchers</h2>
                        </div>
                        <More />
                    </div>
                    <div className='navigationBox' onClick={() => navigate('/mytransactions')}>
                        <div>
                            <MyTransaction />
                            <h2>My Transactions</h2>
                        </div>
                        <More />
                    </div>
                    <div className='navigationBox' onClick={() => navigate('/Privacy-Policies')}>
                        <div>
                            <img src={Privacy} alt="privacy" className='privacyHeight' />
                            <h2>Privacy Policies</h2>
                        </div>
                        <More />
                    </div>
                    <div className='navigationBox' onClick={() => setOpen(true)}>
                        <div>
                            <MyLogout />
                            <h2>LogOut</h2>
                        </div>
                        <More />
                    </div>
                </div>
            </div>

            <Dialog
                open={open}
                onClose={() => handleDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox'>
                    <img src={right} alt="pendong" className='pending' />
                    <p>Are you sure you want to exit</p>
                    <div className='yesNoBox'>
                        <p onClick={() => logout()}>Yes</p>
                        <p onClick={() => handleDelete()}>No</p>
                    </div>
                </div>
            </Dialog>
        </>

    )
}

export default NavBar