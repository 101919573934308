import React, { useEffect, useState } from 'react'
import Header from '../../header/Header'
import "../../assets/css/myVouchers.css"
import axiosClient from '../../axios';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import processing from "../../assets/images/processing.png"


function MyVouchers() {
    const [vouchers, setVouchers] = useState([])
    const [loader, setLoader] = useState(false)

    const [couponOpen, setCouponOpen] = useState(-1);
    const getMyVouchers = () => {
        setLoader(true);
        axiosClient
            .get("customers/api/voucher/order-histories",)
            .then((res) => {
                const transactionData = res.data.response
                setVouchers(transactionData)
            })
            .catch((error) =>
                console.log(error)
            )
            .finally(() => setLoader(false))
    }

    const navigate = useNavigate();
    const coupon = (e) => {
        setCouponOpen(e)
        localStorage.setItem("orderHistory", JSON.stringify(vouchers[e]))
        navigate("/vouhcerhistory")
    }
    useEffect(() => {
        getMyVouchers()
    }, [])
    return (
        <>
            <Header expand={false} heading={"My Vouchers"} newClass={true} />
            <div className='boxCover'>
                <div className='container'>
                    {
                        loader ? <>
                            <div className='processing pro'>
                                <img src={processing} alt="processing" />
                            </div></> :
                            vouchers?.length > 0 ?

                                vouchers?.map((ele, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className='singleVoucher'>
                                                <div className='top flex'>
                                                    <h3 className='topName'>
                                                        {ele.p_name}
                                                    </h3>
                                                    <div className="conatinCouponBox" onClick={() => coupon(index)}>
                                                        <h5 className="view">View Coupon</h5>
                                                    </div>
                                                </div>
                                                <div className='middle'>
                                                    <p>{`Rs. ${ele.amount}`}</p>
                                                </div>
                                                <div className='bottom flex'>
                                                    <div className='topDesc'>
                                                        <h6><span className='time'>{moment.utc(ele.created_at).format('MMM Do YYYY ,  h:mm:ss')}</span></h6>
                                                    </div>

                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                                : <p className='not'>You haven't purchased any vouhcer yet</p>
                    }


                </div>
            </div>
        </>
    )
}

export default MyVouchers