import React from 'react'
import Header from '../../header/Header'

function HowItWorks() {
    return (
        <>
            <Header expand={false} heading={"How It Works ?"} newClass={true} />
            <div className='workCover'>
                <p>Please refer to the below mentioned guidelines before redeeming your <b>Gift Cards</b> and <b>Subscriptions</b>:</p>
                <p>•You can redeem <b>Gift Cards</b> and Subscriptions using
                    <b> Coins</b> and in case if you don't have sufficient amount of
                    <b> Coins</b> available in your wallet/account, you can purchase
                    our <b>Gift Cards</b> and Subscriptions using our payment
                    portal/gateway.</p>
                <p>•<b>Gift Cards</b> and Subscription once redeemed/
                    purchased are non-refundable.</p>
                <p>•Its <b> Expiry date</b> remains on the Gift Card and if you
                    redeem it after its expiry, then the company will not be
                    liable.</p>
                <p>•<b>Gift Cards</b> redeemed will be delivered to you through
                    your Email provided by you and in case if you did not
                    receive email then you can see your Gift Card code in
                    TopUpX's History.</p>
                <p>•You can redeem <b>Gift Cards</b> and Subscriptions up to a
                    certain Daily limit.</p>
                <p>•<b>Gift Cards</b> and Subscriptions redeem daily limit will
                    reset after <b>12 A.M.</b></p>
                <p>•Here you can redeem different types of vouchers
                    including <b>Google Play Gift Card, Khiladi Adda
                        voucher, Flipkart, Amazon, Myntra</b> and many more.</p>
            </div>
        </>
    )
}

export default HowItWorks