import React, { useEffect, useState } from 'react'
import "../assets/css/Header.css"
import { useNavigate } from 'react-router-dom'
import newArrow from "../assets/images/newArrow.svg"
import how from "../assets/images/how.svg"
import MenuIcon from '@mui/icons-material/Menu';
import coin from "../assets/images/rupees.jpeg"
function Header({ expand, payment, heading = "TopUpx", newClass = false, walletCoin = 0 }) {
    const navigate = useNavigate();
    const [expandable, setExpandable] = useState(true);
    const [paymentMode, setPaymentMode] = useState(false)
    // const userInfo = JSON.parse(localStorage.getItem("User_Info"));
    const coins = JSON.parse(localStorage.getItem("coins")) || walletCoin

    useEffect(() => {
        !expand ? setExpandable(false) : setExpandable(true)
        if (payment || newClass) {
            setPaymentMode(true);
        }
    }, []);
    return (
        <>
            <div className={`header ${newClass ? "bgTrue" : ""}`}>
                <div className="headArea flex">
                    <div className='flex'>
                        {expandable ?
                            <>
                                <span className='backBox' onClick={() => navigate('/nav')}>
                                    <MenuIcon className='menuIcons' />
                                </span>
                            </> :
                            <>
                                <span className='backBox ' onClick={() => navigate(-1)}>
                                    <img src={newArrow} alt="backArrow" />
                                </span>
                            </>
                        }

                        <h3 className={`heading ${newClass ? "bgTrue" : ""}`}>{heading}</h3>
                    </div>
                    {paymentMode ? null : <>
                        <div className='flex'>
                            <div className='coins'>
                                <img src={coin} alt="coin" className='coinImg' />
                                <span>{Number(coins).toFixed(2)}</span>
                            </div>
                            {/* <div className='profileBox' onClick={() => navigate('/nav')}>
                                <img src={userInfo?.profile_img} alt={`${userInfo?.name}'s profliePic`} referrerPolicy="no-referrer" />
                            </div> */}
                            <div className='profileBox' onClick={() => navigate('/how')}>
                                <img src={how} alt={`How It Works`} />
                            </div>


                        </div>
                    </>}


                </div>

            </div>
        </>
    )
}

export default Header