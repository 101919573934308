import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Home from './pages/Home/Home';
import Voucher from './pages/Categories/Voucher';
import Payment from './pages/payment/Payment';
import Login from './pages/Login/Login';
import NavBar from './pages/navBar/NavBar';
import MyProfile from "./pages/Profile/MyProfile"
import MyVouchers from "./pages/vouchers/MyVouchers"
import MyTransaction from "./pages/Transactions/MyTransaction"
import Confirmation from './pages/Categories/Confirmation';
import PaymentProcess from './pages/payment/PaymentProcess';
import VouhcerDeatils from './pages/vouchers/VouhcerDeatils';
import HowItWorks from './pages/HowItWorks/HowItWorks';
import PrivacyPolicies from './pages/Privacy-Policies/PrivacyPolicies';
import LoginSuccess from './pages/Login/LoginSuccess';
import Signup from './pages/Signup/Signup';
import SignupSuccess from './pages/Signup/SignupSuccess';


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  function ProtectedRoute({ children }) {
    const jwtToken = JSON.parse(localStorage.getItem("jwt"));
    if (!jwtToken) {
      return <Navigate to="/login" />;
    }
    return children;
  }
  console.log("welcome")
  useEffect(() => {
    const jwtToken = JSON.parse(localStorage.getItem("jwt"));
    if (jwtToken) {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />

          <Route path="/voucher/:id" element={<ProtectedRoute><Voucher /></ProtectedRoute>} />
          <Route path="/payment/" element={<ProtectedRoute><Payment /></ProtectedRoute>} />
          <Route path="/nav" element={<ProtectedRoute><NavBar /></ProtectedRoute>} />
          <Route path="/myProfile" element={<ProtectedRoute><MyProfile /></ProtectedRoute>} />
          <Route path="/myVouchers" element={<ProtectedRoute><MyVouchers /></ProtectedRoute>} />
          <Route path="/confirm" element={<ProtectedRoute><Confirmation /></ProtectedRoute>} />
          <Route path="/mytransactions" element={<ProtectedRoute><MyTransaction /></ProtectedRoute>} />
          <Route path="/paymentprocess" element={<ProtectedRoute><PaymentProcess /></ProtectedRoute>} />
          <Route path="/vouhcerhistory" element={<ProtectedRoute><VouhcerDeatils /></ProtectedRoute>} />
          <Route path="/Privacy-Policies" element={<ProtectedRoute><PrivacyPolicies /></ProtectedRoute>} />
          <Route path="/how" element={<ProtectedRoute><HowItWorks /></ProtectedRoute>} />
          {/* <Route path="/login" element={  <Login />} /> */}
          <Route path="/login" element={isAuthenticated ? <Home /> : <Login />} />
          {/* <Route path="/RequestOtp" element={<RequestOtp />} /> */}
          <Route path="/loginSuccessful" element={<LoginSuccess />} />
          <Route path="/signupSuccessful" element={<SignupSuccess />} />
          <Route path="/signup" element={<Signup />} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
