import React from 'react'
import Header from '../../header/Header'
import moment from 'moment';

function VouhcerDeatils() {
    const orderHistory = JSON.parse(localStorage.getItem("orderHistory"));
    console.log("orderHistory", orderHistory)
    return (
        <>
            <Header expand={false} heading={"Voucher Details"} newClass={true} />
            <div className='boxCover'>
                <div className='container'>
                    <div className='imageCover'>
                        <img src={orderHistory?.p_image} alt="Product" />
                    </div>
                    <div className='order'>
                        <p>Date : {moment(orderHistory?.created_at).utc().format("MMM Do YYYY")}</p>
                        <p>Time : {moment(orderHistory?.created_at).utc().format('LT')} </p>
                        <p>Amount Paid : {orderHistory.price * orderHistory.qty}</p>
                        <p>Voucher Value : {orderHistory.price}</p>
                    </div>

                    <div className='order'>
                        <h3>Voucher Redemption Details</h3>
                        {
                            orderHistory.cards.map((ele, index) => {
                                return (<>
                                    <p><b>Voucher</b> : {index + 1}</p>
                                    <p>Code : {ele?.cardNumber}</p>
                                    <p>Pin : {ele?.cardPin}</p>
                                    <p>Validity : {moment.utc(ele?.validity).format("MMM Do YYYY , h:mm:ss a")}</p>
                                </>)
                            })
                        }
                    </div>
                    <div className='order'>
                        <h3>Voucher Name : {orderHistory?.p_name}</h3>
                        <p>Quantity : {orderHistory?.qty}</p>
                        <p>Total Value : {orderHistory?.qty * orderHistory?.price}</p>
                        <p>Transaction ID : {orderHistory?.v_order_id}</p>

                        <p>Email : {orderHistory?.email}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VouhcerDeatils