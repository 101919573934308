import React, { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import axiosClient from '../../axios';
import processing from "../../assets/images/processing.png"
import tick from "../../assets/images/tick.svg"
import fail from "../../assets/images/fail.svg"
import DisableBackButton from './DisableBackButton';
import { useState } from 'react';
function PaymentProcess() {
    const search = useLocation().search;
    const sku = new URLSearchParams(search).get("sku")
    const price = new URLSearchParams(search).get("price")
    const qty = new URLSearchParams(search).get("qty")
    const email = new URLSearchParams(search).get("email")
    const mobile = new URLSearchParams(search).get("mobile")
    // const is_free_cash = new URLSearchParams(search).get("is_FC")

    const transaction_id = new URLSearchParams(search).get("transaction_id")
    let category_type = new URLSearchParams(search).get("category_type")

    const finalVoucher = {
        transaction_id: transaction_id,
        email: email,
        sku: sku,
        qty: Number(qty),
        price: Number(price),
        mobile: mobile,
        category_type: Number(category_type),
    }

    const [payment, setPayment] = useState(false)
    const [paymentResponse, setPaymentResponse] = useState({ resp: true, message: "We are delighted to inform you that we have received your payment." })

    const navigate = useNavigate();
    const intervalRef = useRef(null); // Create a ref to store the interval ID
    const orderVoucher = async () => {
        localStorage.removeItem("finalVoucher");
        localStorage.removeItem("voucherSelected");
        await axiosClient.get(`/customers/api/phone-pe/payment/${transaction_id}/status`)
            .then((res) => {
                setPayment(true);
                if (res.data.status === false || res.data.status_code === 404) {
                    setPaymentResponse({
                        resp: false,
                        message: res.data.message
                    })
                } else if (res.data.status === true && res.data.status_code === 200) {
                    axiosClient.post("/customers/api/voucher/order", finalVoucher)
                        .then((res) => {
                            if (res.data.status === false) {
                                setPaymentResponse({
                                    resp: false,
                                    message: res.data.message
                                })
                            }
                        })
                        .catch((error) =>
                            console.log(error)
                        );
                }
            })
            .catch((error) =>
                console.log(error)
            );

    }

    // for 3 sec wait
    useEffect(() => {
        if (sku && price && qty && email && mobile && transaction_id) {
            if (!intervalRef.current) {
                intervalRef.current = setInterval(() => {
                    orderVoucher();
                    clearInterval(intervalRef.current);
                    intervalRef.current = null; // Clear the interval ref after it runs once
                }, 3000);
            }
            return () => {
                clearInterval(intervalRef.current); // Clear the interval on component unmount
            };
        }
    }, []);

    // for stopping pull to refresh

    useEffect(() => {
        const preventPullToRefresh = (event) => {
            // Prevent the default behavior only when the user pulls down
            if (event.touches.length > 1) return;
            event.preventDefault();
        }
        document.addEventListener('touchmove', preventPullToRefresh, { passive: false });
        return () => {
            document.removeEventListener('touchmove', preventPullToRefresh);
        };
    }, []);


    return (
        <div className="processScreen">
            <div className='paymentProcessing'>
                {
                    payment ?
                        <>
                            <div className='processing setting tick'>
                                <img src={paymentResponse.resp ? tick : fail} alt="tick" />
                            </div>
                        </> :
                        <>
                            <div className='processing setting'>
                                <img src={processing} alt="processing" />
                            </div>
                        </>
                }
                <h3 className='processHead'>
                    {
                        payment ?
                            <>
                                {paymentResponse.resp ? "Payment Successful" : "Payment Failed"}
                            </>
                            : <>
                                Payment Processing...
                            </>
                    }

                </h3>
                {
                    payment ?
                        <>
                            <p>{paymentResponse.message}</p>
                        </>
                        : <>
                            <p>You transaction is being processed. This may take a couple of minutes.</p>
                        </>
                }

                {
                    payment ?
                        <div className='payBox w90' onClick={() => navigate("/", { replace: true })}>
                            Okay
                        </div> : null
                }
            </div>
            <DisableBackButton />
        </div >

    )
}

export default PaymentProcess